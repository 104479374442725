angular.module('qualita')
  .directive('loadingContainer', function () {
      return {
          restrict: 'AC',
          link: function (scope, el, attrs) {
              el.removeClass('app-loading');
              scope.$on('$stateChangeStart', function (event) {
                  el.addClass('app-loading');
              });
              scope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState) {
                  event.targetScope.$watch('$viewContentLoaded', function() {
                      el.removeClass('app-loading ');
                  });
              });
          }
      };
  });
//Chat Toggle Link
angular.module('qualita')
    .directive('skinChanger', ['$rootScope', '$filter','$state', '$stateParams', function ($rootScope, $filter, $state, $stateParams) {
        return {
            restrict: 'AC',
            link: function (scope, el, attr) {
                el.on('click', function () {
                    setTimeout(function () {
                        $rootScope.$apply(function () {
                            var skincolor = $filter('filter')(scope.skins, function (d) { return d.skin === el.attr('rel'); })[0];
                            if (!skincolor) {
                                skincolor = scope.skins[1];
                            }
                            $rootScope.settings.skin = skincolor.skin;
                            $rootScope.settings.color = skincolor.color;
                            $state.transitionTo($state.current, $stateParams, {
                                reload: true,
                                inherit: false,
                                notify: true
                            });
                        });
                    }, 100);
                });
                scope.skins = [
                    {
                        skin: 'theme/assets/css/skins/blue.min.css',
                        color: {
                            themeprimary: '#5db2ff',
                            themesecondary: '#ed4e2a',
                            themethirdcolor: '#ffce55',
                            themefourthcolor: '#a0d468',
                            themefifthcolor: '#e75b8d'
                        }
                    },
                    {
                        skin: 'theme/assets/css/skins/azure.min.css',
                        color: {
                            themeprimary: '#2dc3e8',
                            themesecondary: '#fb6e52',
                            themethirdcolor: '#ffce55',
                            themefourthcolor: '#a0d468',
                            themefifthcolor: '#e75b8d'
                        }
                    },
                    {
                        skin: 'theme/assets/css/skins/black.min.css',
                        color: {
                            themeprimary: '#474544',
                            themesecondary: '#d73d32',
                            themethirdcolor: '#ffce55',
                            themefourthcolor: '#a0d468',
                            themefifthcolor: '#e75b8d'
                        }
                    },
                    {
                        skin: 'theme/assets/css/skins/darkblue.min.css',
                        color: {
                            themeprimary: '#0072c6',
                            themesecondary: '#fb6e52',
                            themethirdcolor: '#ffce55',
                            themefourthcolor: '#a0d468',
                            themefifthcolor: '#e75b8d'
                        }
                    },
                    {
                        skin: 'theme/assets/css/skins/darkred.min.css',
                        color: {
                            themeprimary: '#ac193d',
                            themesecondary: '#7bd148',
                            themethirdcolor: '#5db2ff',
                            themefourthcolor: '#e75b8d',
                            themefifthcolor: '#ffce55'
                        }
                    },
                    {
                        skin: 'theme/assets/css/skins/deepblue.min.css',
                        color: {
                            themeprimary: '#001940',
                            themesecondary: '#d73d32',
                            themethirdcolor: '#ffce55',
                            themefourthcolor: '#a0d468',
                            themefifthcolor: '#e75b8d'
                        }
                    },
                    {
                        skin: 'theme/assets/css/skins/deepblue.min.css',
                        color: {
                            themeprimary: '#001940',
                            themesecondary: '#d73d32',
                            themethirdcolor: '#ffce55',
                            themefourthcolor: '#a0d468',
                            themefifthcolor: '#e75b8d'
                        }
                    },
                    {
                        skin: 'theme/assets/css/skins/gray.min.css',
                        color: {
                            themeprimary: '#585858',
                            themesecondary: '#fb6e52',
                            themethirdcolor: '#ffce55',
                            themefourthcolor: '#a0d468',
                            themefifthcolor: '#e75b8d'
                        }
                    },
                    {
                        skin: 'theme/assets/css/skins/green.min.css',
                        color: {
                            themeprimary: '#53a93f',
                            themesecondary: '#ed4e2a',
                            themethirdcolor: '#ffce55',
                            themefourthcolor: '#a0d468',
                            themefifthcolor: '#e75b8d'
                        }
                    },
                    {
                        skin: 'theme/assets/css/skins/orange.min.css',
                        color: {
                            themeprimary: '#ff8f32',
                            themesecondary: '#7bd148',
                            themethirdcolor: '#5db2ff',
                            themefourthcolor: '#a0d468',
                            themefifthcolor: '#e75b8d'
                        }
                    },
                    {
                        skin: 'theme/assets/css/skins/pink.min.css',
                        color: {
                            themeprimary: '#cc324b',
                            themesecondary: '#8cc474',
                            themethirdcolor: '#ffce55',
                            themefourthcolor: '#a0d468',
                            themefifthcolor: '#e75b8d'
                        }
                    },
                    {
                        skin: 'theme/assets/css/skins/purple.min.css',
                        color: {
                            themeprimary: '#8c0095',
                            themesecondary: '#ffce55',
                            themethirdcolor: '#e75b8d',
                            themefourthcolor: '#a0d468',
                            themefifthcolor: '#fb6e52'
                        }
                    },
                    {
                        skin: 'theme/assets/css/skins/teal.min.css',
                        color: {
                            themeprimary: '#03b3b2',
                            themesecondary: '#ed4e2a',
                            themethirdcolor: '#ffce55',
                            themefourthcolor: '#a0d468',
                            themefifthcolor: '#fb6e52'
                        }
                    }
                ];
            }
        };
    }]);
//Sidebar Menu Handle
angular.module('qualita')
    .directive('sidebarMenu', function() {
        return {
            restrict: 'AC',
            link: function (scope, el, attr) {
                el.find('li.active').parents('li').addClass('active open');

                el.on('click', 'a', function (e) {
                    e.preventDefault();
                    var isCompact = $("#sidebar").hasClass("menu-compact");
                    var menuLink = $(e.target);
                    if ($(e.target).is('span'))
                        menuLink = $(e.target).closest('a');
                    if (!menuLink || menuLink.length == 0)
                        return;
                    if (!menuLink.hasClass("menu-dropdown")) {
                        if (isCompact && menuLink.get(0).parentNode.parentNode == this) {
                            var menuText = menuLink.find(".menu-text").get(0);
                            if (e.target != menuText && !$.contains(menuText, e.target)) {
                                return false;
                            }
                        }
                        return;
                    }
                    var submenu = menuLink.next().get(0);
                    if (!$(submenu).is(":visible")) {
                        var c = $(submenu.parentNode).closest("ul");
                        if (isCompact && c.hasClass("sidebar-menu"))
                            return;
                        c.find("* > .open > .submenu")
                            .each(function() {
                                if (this != submenu && !$(this.parentNode).hasClass("active"))
                                    $(this).slideUp(200).parent().removeClass("open");
                            });
                    }
                    if (isCompact && $(submenu.parentNode.parentNode).hasClass("sidebar-menu"))
                        return false;
                    $(submenu).slideToggle(200).parent().toggleClass("open");
                    return false;
                });
            }
        };
    });


angular.module('qualita')
    .directive('fullscreen', function() {
            return {
                restrict: 'AC',
                template: '<i class="glyphicon glyphicon-fullscreen"></i>',
                link: function(scope, el, attr) {
                    el.on('click', function() {
                        var element = document.documentElement;
                        if (!$('body')
                            .hasClass("full-screen")) {

                            $('body')
                                .addClass("full-screen");
                            $('#fullscreen-toggler')
                                .addClass("active");
                            if (element.requestFullscreen) {
                                element.requestFullscreen();
                            } else if (element.mozRequestFullScreen) {
                                element.mozRequestFullScreen();
                            } else if (element.webkitRequestFullscreen) {
                                element.webkitRequestFullscreen();
                            } else if (element.msRequestFullscreen) {
                                element.msRequestFullscreen();
                            }

                        } else {

                            $('body').removeClass("full-screen");
                            el.removeClass("active");

                            if (document.exitFullscreen) {
                                document.exitFullscreen();
                            } else if (document.mozCancelFullScreen) {
                                document.mozCancelFullScreen();
                            } else if (document.webkitExitFullscreen) {
                                document.webkitExitFullscreen();
                            }

                        }
                    });
                }
            };
        }
    );

angular.module('qualita')
    .directive('refreshPage', [
        '$rootScope', '$state', '$stateParams',
        function($rootScope, $state, $stateParams) {
            return {
                restrict: 'AC',
                template: '<i class="glyphicon glyphicon-refresh"></i>',
                link: function(scope, el, attr) {
                    el.on('click', function() {
                        $state.transitionTo($state.current, $stateParams, {
                            reload: true,
                            inherit: false,
                            notify: true
                        });
                    });
                }
            };
        }
    ]);

angular.module('qualita')
    .directive('sidebarToggler', function() {
            return {
                restrict: 'AC',
                template: '<i class="fa fa-arrows-h"></i>',
                link: function(scope, el, attr) {
                    el.on('click', function() {
                        $("#sidebar").toggleClass("hide");
                        el.toggleClass("active");
                        return false;
                    });
                }
            };
        }
    );

angular.module('qualita')
    .directive('pageTitle', [
        '$rootScope', '$timeout',
        function($rootScope, $timeout) {
            return {
                link: function(scope, element) {

                    var listener = function(event, toState) {
                        var title = 'Default Title';
                        if (toState.ncyBreadcrumb && toState.ncyBreadcrumb.label) title = toState.ncyBreadcrumb.label;
                        $timeout(function() {
                            element.text(title);
                        }, 0, false);
                    };
                    $rootScope.$on('$stateChangeSuccess', listener);
                }
            };
        }
    ]);

angular.module('qualita')
    .directive('headerTitle', [
        '$rootScope', '$timeout',
        function ($rootScope, $timeout) {
            return {
                link: function (scope, element) {
                    var listener = function (event, toState) {
                        var title = 'Default Title';
                        var description = '';

                        if (toState) {
                            if (toState.ncyBreadcrumb) title = toState.ncyBreadcrumb.label;
                            if (toState.ncyBreadcrumb) description = toState.ncyBreadcrumb.description;
                        } else {
                            if ($rootScope.title) title = $rootScope.title;
                            if ($rootScope.description) description = $rootScope.description;
                        }

                        $timeout(function () {
                            if (description == '') {
                                element.text(title);
                            } else {
                                element.html(title + ' <small> <i class="fa fa-angle-right"> </i> '+ description + ' </small>');
                            }
                        }, 0, false);
                    }

                    listener();
                    $rootScope.$on('$stateChangeSuccess', listener);
                }
            };
        }
    ]);
//Sidebar Collapse
angular.module('qualita')
    .directive('sidebarCollapsed',  ['$rootScope', function ($rootScope) {
        return {
            restrict: 'AC',
            template: '<i class="collapse-icon fa fa-bars"></i>',
            scope: true,
            link: function (scope, el, attr) {
                el.on('click', function () {
                    if (!$('#sidebar').is(':visible'))
                        $("#sidebar").toggleClass("hide");
                    $("#sidebar").toggleClass("menu-compact");
                    
                    $(".navbar-header.pull-left").toggleClass("show");
                    $(".sidebar-collapse").toggleClass("active");
                    var isCompact = $("#sidebar").hasClass("menu-compact");
                    
                    if ($(".sidebar-menu").closest("div").hasClass("slimScrollDiv")) {
                        $(".sidebar-menu").slimScroll({ destroy: true });
                        $(".sidebar-menu").attr('style', '');
                    }
                    if (isCompact) {
                        $(".open > .submenu")
                            .removeClass("open");

                        
                        console.log($rootScope);
                        $rootScope.query.menu = '';
                        $rootScope.$digest();
                    } else {
                        if ($('.page-sidebar').hasClass('sidebar-fixed')) {
                            var position = (readCookie("rtl-support") || location.pathname == "/index-rtl-fa.html" || location.pathname == "/index-rtl-ar.html") ? 'right' : 'left';
                            $('.sidebar-menu').slimscroll({
                                height: 'auto',
                                position: position,
                                size: '3px',
                                color: themeprimary
                            });
                        }
                    }

                    //Slim Scroll Handle
                });
            }
        };
    }]);

//Setting
angular.module('qualita')
    .directive('setting', function () {
        return {
            restrict: 'AC',
            template: '<a id="btn-setting" title="Setting" href="#"><i class="icon glyphicon glyphicon-cog"></i></a>',
            link: function (scope, el, attr) {
                el.on('click', function () {
                    $('.navbar-account').toggleClass('setting-open');
                });
            }
        };
    });

//Chat Toggle Link
angular.module('qualita')
    .directive('chatLink', function () {
        return {
            restrict: 'AC',
            template: '<i class="icon glyphicon glyphicon-comment"></i>',
            link: function (scope, el, attr) {
                el.on('click', function () {
                    $('.page-chatbar').toggleClass('open');
                    el.toggleClass('wave').toggleClass('in');
                    el.parent().toggleClass('open');
                });
            }
        };
    });


angular.module('qualita')
    .directive('pageChatbar', ['$cookies', function ($cookies) {
        return {
            restrict: 'AC',
            link: function (scope, el, attr) {
                var position = ($cookies.rtlSupport || location.pathname == "/index-rtl-fa.html" || location.pathname == "/index-rtl-ar.html") ? 'right' : 'left';
                $('.chatbar-messages .messages-list').slimscroll({
                    position: position,
                    size: '4px',
                    color: scope.settings.color.themeprimary,
                    height: $(window).height() - 250,
                });
                $('.chatbar-contacts .contacts-list').slimscroll({
                    position: position,
                    size: '4px',
                    color: scope.settings.color.themeprimary,
                    height: $(window).height() - 86,
                });
                el.on('click', '.chatbar-contacts .contact', function () {
                    el.find('.chatbar-contacts').hide();
                    el.find('.chatbar-messages').show();
                });

                el.on('click', '.chatbar-messages .back', function () {
                    el.find('.chatbar-messages').hide();
                    el.find('.chatbar-contacts').show();
                });
            }
        };
    }]);
//Maximize Widget
angular.module('qualita')
    .directive('widgetMaximize', function () {
        return {
            restrict: 'A',
            template: '<i class="fa fa-expand"></i>',
            link: function (scope, el, attr) {
                el.on('click', function () {
                    var widget = el.parents(".widget").eq(0);
                    var button = el.find("i").eq(0);
                    var compress = "fa-compress";
                    var expand = "fa-expand";
                    if (widget.hasClass("maximized")) {
                        if (button) {
                            button.addClass(expand).removeClass(compress);
                        }
                        widget.removeClass("maximized");
                        widget.find(".widget-body").css("height", "auto");
                    } else {
                        if (button) {
                            button.addClass(compress).removeClass(expand);
                        }
                        widget.addClass("maximized");
                        if (widget) {
                            var windowHeight = $(window).height();
                            var headerHeight = widget.find(".widget-header").height();
                            widget.find(".widget-body").height(windowHeight - headerHeight);
                        }
                    }
                });
            }
        };
    });

//Collapse Widget
angular.module('qualita')
    .directive('widgetCollapse', function () {
        return {
            restrict: 'A',
            template: '<i class="fa fa-minus"></i>',
            link: function (scope, el, attr) {
                el.on('click', function () {
                    var widget = el.parents(".widget").eq(0);
                    var body = widget.find(".widget-body");
                    var button = el.find("i");
                    var down = "fa-plus";
                    var up = "fa-minus";
                    var slidedowninterval = 300;
                    var slideupinterval = 200;
                    if (widget.hasClass("collapsed")) {
                        if (button) {
                            button.addClass(up).removeClass(down);
                        }
                        widget.removeClass("collapsed");
                        body.slideUp(0, function () {
                            body.slideDown(slidedowninterval);
                        });
                    } else {
                        if (button) {
                            button.addClass(down)
                                .removeClass(up);
                        }
                        body.slideUp(slideupinterval, function () {
                            widget.addClass("collapsed");
                        });
                    }
                });
            }
        };
    });

//Expand Widget
angular.module('qualita')
    .directive('widgetExpand', function () {
        return {
            restrict: 'A',
            template: '<i class="fa fa-plus"></i>',
            link: function (scope, el, attr) {
                el.on('click', function () {
                    var widget = el.parents(".widget").eq(0);
                    var body = widget.find(".widget-body");
                    var button = el.find("i");
                    var down = "fa-plus";
                    var up = "fa-minus";
                    var slidedowninterval = 300;
                    var slideupinterval = 200;
                    if (widget.hasClass("collapsed")) {
                        if (button) {
                            button.addClass(up).removeClass(down);
                        }
                        widget.removeClass("collapsed");
                        body.slideUp(0, function () {
                            body.slideDown(slidedowninterval);
                        });
                    } else {
                        if (button) {
                            button.addClass(down)
                                .removeClass(up);
                        }
                        body.slideUp(slideupinterval, function () {
                            widget.addClass("collapsed");
                        });
                    }
                });
            }
        };
    });

//Dispose Widget
angular.module('qualita')
    .directive('widgetDispose', function () {
        return {
            restrict: 'A',
            template: '<i class="fa fa-times"></i>',
            link: function (scope, el, attr) {
                el.on('click', function () {
                    var widget = el.parents(".widget").eq(0);
                    var disposeinterval = 300;
                    widget.hide(disposeinterval, function () {
                        widget.remove();
                    });
                });
            }
        };
    });

//Config Widget
angular.module('qualita')
    .directive('widgetConfig', function () {
        return {
            restrict: 'A',
            template: '<i class="fa fa-cog"></i>',
            link: function (scope, el, attr) {
                el.on('click', function () {
                   //Do what you intend for configing widgets
                });
            }
        };
    });

//Config Widget
angular.module('qualita')
    .directive('widgetRefresh', function () {
        return {
            restrict: 'A',
            template: '<i class="fa fa-undo"></i>',
            link: function (scope, el, attr) {
                el.on('click', function () {
                    //Refresh widget content
                });
            }
        };
    });
app.controller('DateRangePickerCtrl', ["$scope", function($scope) {
    $scope.date = { startDate: '2015-02-23', endDate: '2015-03-01' };
}]);